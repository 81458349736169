@import '~antd/dist/antd.css';

@page { 
  size: auto;
  margin: 4mm 16mm 4mm 16mm;
}
@media print {
  .graph-img img {
      display: inline;
  }
}
@media print {
  .page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .page-breaker {
    page-break-after: always;
  }
}

.m-signature-pad {
  position: absolute;
  font-size: 10px;
  width: 700px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-left: -350px;
  margin-top: -200px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.m-signature-pad--body {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 60px;
  border: 1px solid #f4f4f4;
}

.m-signature-pad--body
  canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  }

.m-signature-pad--footer {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  height: 40px;
}

.m-signature-pad--footer
  .description {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
  }

.m-signature-pad--footer
  .button {
    position: absolute;
    bottom: 0;
  }

.m-signature-pad--footer
  .button.clear {
    left: 0;
  }

.m-signature-pad--footer
  .button.save {
    right: 0;
  }

@media screen and (max-width: 1024px) {
  .m-signature-pad {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    min-width: 250px;
    min-height: 140px;
    margin: 5%;
  }
  #github {
    display: none;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .m-signature-pad {
    margin: 10%;
  }
}

@media screen and (max-height: 320px) {
  .m-signature-pad--body {
    left: 0;
    right: 0;
    top: 0;
    bottom: 32px;
  }
  .m-signature-pad--footer {
    left: 20px;
    right: 20px;
    bottom: 4px;
    height: 28px;
  }
  .m-signature-pad--footer
    .description {
      font-size: 1em;
      margin-top: 1em;
    }
}

.hover-selectable:hover { 
  cursor: pointer;
  background-color: #E0F7FA;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.freeze-scroll {
  overflow: hidden;
}
.freeze-scroll-x {
  overflow-x: hidden;
}
.self-scroll {
  overflow-y: auto;
}
.self-scroll-x {
  overflow-x: auto;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.text-center {
  text-align: center;
}
.flex {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column; /* Safari */
}
.flex-fill {
    -webkit-flex: 1; /* Safari */
    flex: 1;
}
.flex-row {
    flex-direction: row;
    -webkit-flex-direction: row; /* Safari */
}
.flex-column {
    flex-direction: column;
    -webkit-flex-direction: column; /* Safari */
}
.flex-center {
    -webkit-justify-content: center; /* Safari */
    justify-content: center;
}
.flex-wrap {
    -webkit-flex-wrap: wrap; /* Safari */
    flex-wrap: wrap;
}
.flex-space-between {
    -webkit-justify-content: space-between; /* Safari */
    justify-content: space-between;
}
.flex-space-around {
    -webkit-justify-content: space-around; /* Safari */
    justify-content: space-around;
}
.flex-align-center {
    align-items: center;
    -webkit-align-items: center; /* Safari */
}
.flex-align-start {
    align-items: flex-start;
    -webkit-align-items: flex-start; /* Safari */
}
.flex-align-end {
    align-items: flex-end;
    -webkit-align-items: flex-end; /* Safari */
}
.flex-end {
    justify-content: flex-end;
    -webkit-justify-content: flex-end; /* Safari */
}
.margin {
    margin: 16px;
}
.margin-half {
    margin: 8px;
}
.margin-top {
    margin-top: 16px;
}
.margin-top-half {
  margin-top: 8px;
}
.margin-bottom {
    margin-bottom: 16px;
}
.margin-bottom-half {
  margin-bottom: 8px;
}
.margin-horizontal {
    margin: 0px 16px;
}
.margin-horizontal-half {
  margin: 0px 8px;
}
.margin-vertical {
    margin: 16px 0px;
}
.margin-right {
    margin-right: 16px;
}
.margin-left {
    margin-left: 16px;
}
.padding-half {
   padding: 8px;
}
.padding {
    padding: 16px;
}
.padding-top {
  padding-top: 16px;
}
.padding-top-half {
    padding-top: 8px;
}
.padding-bottom {
    padding-bottom: 16px;
}
.padding-bottom-half {
  padding-bottom: 8px;
}
.padding-left {
    padding-left: 16px;
}
.padding-left-half {
  padding-left: 8px;
}
.padding-right {
    padding-right: 16px;
}
.padding-right-half {
  padding-right: 8px;
}
.padding-horizontal {
    padding: 0px 16px;
}
.padding-horizontal-half {
    padding: 0px 8px;
}
.padding-vertical {
    padding: 16px 0px;
}
.padding-vertical-half {
  padding: 8px 0px;
}

.bus-font {
  font-size: 1.3125em;
}
.large-font {
  font-size: 1.8em;
}
.mid-large-font {
  font-size: 1.6em;
}
.mid-font {
  font-size: 1.4em;
}
.mid-small-font {
  font-size: 1.2em;
}
.small-font {
  font-size: 1.0em;
}
.font-heighlight {
  color: green;
}
.font-error {
  color: red;
}
.font-warning {
  color: orange;
}
.font-primary {
  color: #1890ff;
}
.font-blue {
  color: #3F51B5;
}
.font-brown {
  color: #795548;
}